<template>
  <div class="sub-header-thin-div">
    <v-card :class="isMobile ? 'mx-auto my-10' : 'mx-auto my-20'" :max-width="isMobile ? '90%' : '70%'">
      <br/><br/><br/>
      <v-card-text :class="isMobile ? 'my-1 text-center' : 'my-10 text-center'">
        <span :class="`font-bold color-purple ${isMobile ? 'font-18' : 'font-30'}`">{{ $t('imprint') }}</span>
      </v-card-text>
      <v-card-text class="m-5" :style="isMobile ? 'font-size: 12px;' : 'font-size: 20px;'">
        <div>
          <p>
            Angaben gemäß § 5 TMG
            <br />
            Geschäftsführerin:
            <br />
            Buket Burc
            <br />
          </p>
          <p>
            Geschäftsstelle:
            <br />
            Am Strassanger 2
            <br />
            86470 Thannhausen
            <br />
          </p>
          <p>
            Steuernummer: 151/125/90053
            <br />
            UST ID: DE361515286
            <br />
            Kontakt: <a href="mailto:info@eventboxde.com">info@eventboxde.com</a>
            <br />
          </p>
          <p>
            EU-Streitschlichtung
            <br />
            Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS)
            <br />
            bereit: <a href="https://lec.europa.eu/consumers/odr" target="_blank">https://lec.europa.eu/consumers/odr</a>
            <br />
            Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
            <br />
            <br />
            <br />
          </p>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    }
  }
};
</script>